import 'prism-themes/themes/prism-material-light.css';
import 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-elm';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-typescript';
import ReactDOM from 'react-dom/client';

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash !== '') {
    if (location.hash === '#top') {
      window.scrollTo(0, 0);
    }
    return location.hash.substr(1);
  }
  return true;
};
